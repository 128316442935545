import React from "react";
import About from "../components/about";
import Contact from "../components/contact";
import Introduction from "../components/introduction";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Work from "../components/work";

const IndexPage = () => (
  <Layout>
    <SEO />
    <Introduction />
    <Work />
    <About />
    <Contact />
  </Layout>
);

export default IndexPage;
