import React from "react";
import Button from "../components/button";
import Container from "../components/container";
import Heading from "../components/heading";
import Input from "../components/input";
import Paragraph from "../components/paragraph";
import Tier from "../components/tier";
import "./contact.scss";

const Contact = () => (
  <Tier id="contact">
    <Container>
      <div className="contact">
        <div className="contact__text">
          <Heading element="1" type="1" variant="contact">
            Oh hello there, let's chat!
          </Heading>
          <Paragraph variant="contact">
            Reach out if you would like to work together, or just to say hi.
          </Paragraph>
        </div>
        <form
          className="contact__form"
          method="POST"
          data-netlify="true"
          action="/thanks"
        >
          <input type="hidden" name="form-name" value="form 1" />
          <Input type="text" id="name" label="Your name" />
          <Input type="email" id="email" label="Your email address" />
          <Input type="textarea" id="message" label="Your message" />
          <Button buttonType="submit" type="primary" variant="contact-submit">
            Send message
          </Button>
        </form>
      </div>
    </Container>
  </Tier>
);

export default Contact;
