import React from "react";
import { Link } from "gatsby";
import "./menu.scss";

class Menu extends React.Component {
  constructor(props) {
    super();
    this.expandedMenuClass = "js-menu-expanded";
    this.toggleMenu = this.toggleMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener("click", e => {
      if (!e.target.closest(".menu")) {
        this.hideMenu();
      }
    });
  }

  toggleMenu() {
    document.body.classList.toggle(this.expandedMenuClass);
  }

  hideMenu() {
    document.body.classList.remove(this.expandedMenuClass);
  }

  render() {
    return (
      <>
        <nav className="menu">
          <button className="menu__toggle" onClick={this.toggleMenu}>
            Toggle menu
          </button>
          <div className="menu__overlay">
            <ul className="menu__items">
              <li className="menu__item">
                <Link
                  to="/#work"
                  className="menu__link"
                  onClick={this.hideMenu}
                >
                  Work
                </Link>
              </li>
              <li className="menu__item">
                <Link
                  to="/#about"
                  className="menu__link"
                  onClick={this.hideMenu}
                >
                  About
                </Link>
              </li>
              <li className="menu__item">
                <Link
                  to="/#contact"
                  className="menu__link"
                  onClick={this.hideMenu}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </>
    );
  }
}

export default Menu;
