import React from "react";
import "./input.scss";

function Input({ id, label, type }) {
  let classes = [`input`, `input--${type}`];

  if (type === "textarea") {
    return (
      <>
        <label className="input-label" htmlFor={id}>
          {label}
        </label>
        <textarea className={classes.join(" ")} id={id} name={id}></textarea>
      </>
    );
  }

  return (
    <>
      <label className="input-label" htmlFor={id}>
        {label}
      </label>
      <input
        className={classes.join(" ")}
        type={type}
        id={id}
        name={id}
        required
      />
    </>
  );
}

export default Input;
