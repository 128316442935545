import React from "react";
import { Link } from "gatsby";
import "./jump-link.scss";

function JumpLink({ children, to, variant }) {
  let classes = [`jump-link`, `jump-link--${to}`];

  return (
    <Link
      to={`/#${to}`}
      className={classes.join(" ")}
    >{`Navigate to ${to}`}</Link>
  );
}

export default JumpLink;
