import React from "react";
import "./portrait.scss";

import portraitImageSm from "../images/portrait-sm.jpg";
import portraitImageMd from "../images/portrait-md.jpg";
import portraitImageLg from "../images/portrait-lg.jpg";

const Portrait = () => {
  return (
    <div className="portrait">
      <picture>
        <source media="(min-width: 1024px)" srcSet={portraitImageLg} />
        <source media="(min-width: 768px)" srcSet={portraitImageMd} />
        <source media="(min-width: 0px)" srcSet={portraitImageSm} />
        <img
          alt="Melanie Lepold at Great Sand Dunes National Park"
          src={portraitImageLg}
        />
      </picture>
    </div>
  );
};

export default Portrait;
