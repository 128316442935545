import React from "react";
import Container from "../components/container";
import Tier from "../components/tier";
import WorkCard from "../components/work-card";
import "./work.scss";

import mail from "../images/work/mail/work-card.jpg";
import office from "../images/work/office/work-card.jpg";
import report from "../images/work/report/work-card.jpg";
import portfolio from "../images/work/portfolio/work-card.jpg";

const Work = () => (
  <Tier id="work">
    <Container>
      <ul className="work">
        <li className="work__item">
          <WorkCard
            project="mail"
            title="Direct Mail Campaign"
            description="A Successful Refresh Resulting in Increased Customer Activations"
            alt="Direct mail letter, insert, and envelope"
            src={mail}
          />
        </li>
        <li className="work__item">
          <WorkCard
            project="portfolio"
            title="Portfolio Design"
            description="Designing a Responsive Website and Cohesive Personal Brand"
            alt="Tablet and phones floating in space with portfolio design on the screen."
            src={portfolio}
          />
        </li>
        <li className="work__item">
          <WorkCard
            project="office"
            title="Denver Office Graphics"
            description="Wall Designs for a Collaborative and Contemporary Workspace"
            alt="Geometric wall graphic"
            src={office}
          />
        </li>
        <li className="work__item">
          <WorkCard
            project="report"
            title="DISH Cares Annual Report"
            description="Showing a TV Provider’s Commitment to Making a Positive Impact"
            alt="Three stacked annual report booklets"
            src={report}
          />
        </li>
      </ul>
    </Container>
  </Tier>
);

export default Work;
