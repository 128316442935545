import React from "react";
import Container from "../components/container";
import Heading from "../components/heading";
import Paragraph from "../components/paragraph";
import Portrait from "../components/portrait";
import Tier from "../components/tier";
import "./about.scss";

const About = () => (
  <Tier id="about">
    <Container>
      <div className="about">
        <Heading element="1" type="1" variant="about-1">
          I'm a creative thinker…
        </Heading>
        <Portrait />
        <Heading element="1" type="1" variant="about-2">
          …looking to build meaningful experiences through design.
        </Heading>
        <div className="about__lower">
          <Paragraph variant="about">
            <strong>I am a graphic designer with 8 years of experience,</strong>{" "}
            most recently at DISH Network’s in-house creative agency. While at
            DISH, I learned about designing for the customer by interpreting
            marketing research.
          </Paragraph>
          <Paragraph variant="about">
            For me, a successful project is not only about creating appealing
            visual aesthetics. It’s also about designing with purpose, and
            creating positive experiences through design.
          </Paragraph>
          <Paragraph variant="about">
            Creativity motivates me to keep improving. Whether it’s through art,
            design, or enjoying the outdoors, I strive to stay inspired and
            learn something new every day.
          </Paragraph>
        </div>
      </div>
    </Container>
  </Tier>
);

export default About;
