import React from "react";
import Container from "./container";
import Menu from "./menu";
import Paragraph from "./paragraph";
import "./layout.scss";

import logo from "../images/logo-dark.svg";

const Layout = ({ children, showMenu = true }) => {
  const year = new Date().getFullYear();

  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]');
    require("what-input");
  }

  return (
    <>
      {showMenu && (
        <Container variant="menu">
          <Menu />
        </Container>
      )}
      <div className="layout">
        <header>
          <Container>
            <img alt="Melanie Lepold's logo" src={logo} />
          </Container>
        </header>
        <main>{children}</main>
        <footer>
          <Container>
            <Paragraph variant="footer">{`© ${year} Melanie Lepold`}</Paragraph>
          </Container>
        </footer>
      </div>
    </>
  );
};

export default Layout;
