import React from "react";
import { Link } from "gatsby";
import Container from "../components/container";
import Heading from "../components/heading";
import JumpLink from "../components/jump-link";
import Tier from "../components/tier";

const Introduction = () => (
  <Tier id="introduction">
    <Container>
      <div className="introduction">
        <Heading element="1" type="1" variant="introduction">
          Hi! I'm <Link to="/#about">Melanie Lepold</Link>, a designer living in
          Golden, CO.
        </Heading>
        <JumpLink to="work" />
      </div>
    </Container>
  </Tier>
);

export default Introduction;
