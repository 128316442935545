import React from "react";
import { Link } from "gatsby";
import "./button.scss";

function Button({ buttonType, children, state, to, type, variant }) {
  let classes = [`button`];
  if (type) {
    classes.push(`button--${type}`);
  }
  if (variant) {
    classes.push(`button--${variant}`);
  }

  if (to) {
    return (
      <Link to={to} className={classes.join(" ")} state={state}>
        {children}
      </Link>
    );
  }
  return (
    <button type={buttonType} className={classes.join(" ")} state={state}>
      {children}
    </button>
  );
}

export default Button;
