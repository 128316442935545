import React from "react";
import "./container.scss";

function Container({ children, variant }) {
  let classes = [`container`];
  if (variant) {
    classes.push(`container--${variant}`);
  }

  return <div className={classes.join(" ")}>{children}</div>;
}

export default Container;
