import React from "react";
import { Link } from "gatsby";
import Button from "./button";
import Heading from "./heading";
import "./work-card.scss";

class WorkCard extends React.Component {
  constructor(props) {
    super();
    this.alt = props.alt;
    this.description = props.description;
    this.project = props.project;
    this.src = props.src;
    this.title = props.title;
    this.classes = ["work-card", `work-card--${this.project}`];
  }

  handleHover(hovered) {
    const hoverClass = "work-card--hovered";

    if (hovered) {
      this.el.classList.add(hoverClass);
    } else {
      this.el.classList.remove(hoverClass);
    }
  }

  render() {
    return (
      <div className={this.classes.join(" ")} ref={el => (this.el = el)}>
        <Link
          className="work-card__image-wrapper"
          onMouseOver={e => this.handleHover(true)}
          onMouseOut={e => this.handleHover(false)}
          to={`/work/${this.project}/`}
          state={{
            modal: true,
          }}
          tabIndex={-1}
        >
          <img src={this.src} alt={this.alt} className="work-card__image" />
        </Link>
        <Heading element="2" type="2" variant="work-card-title">
          {this.title}
        </Heading>
        <Heading element="3" type="3" variant="work-card-description">
          {this.description}
        </Heading>
        <div
          onMouseOver={e => this.handleHover(true)}
          onMouseOut={e => this.handleHover(false)}
        >
          <Button
            type="primary"
            variant="work-card-button"
            to={`/work/${this.project}/`}
            state={{
              modal: true,
            }}
          >
            See more
          </Button>
        </div>
      </div>
    );
  }
}

export default WorkCard;
